<template>
  
	<section class="projects">
		<div class="container ">

			<div class="projects__container container__inner">
				<div class="projects__wrapper">
					
					<div v-for='(project, index) in projects' :key='"projects" + index' class="projects__block">
						<router-link :to="project.pageLink">
							<div class="projects__layer"></div>
							<div class="projects__img">
								<img :src="project.rel" alt="project">
							</div>
							<div class="projects__text">
								<h2 class="projects__text__title" v-html='project.title'></h2>
								<p class="projects__text__case-button">look case</p>
							</div>
							<p class="projects__letter" >{{project.title.split('')[0]}}</p>
						</router-link>
					</div>

				</div>
			</div>

		</div>
	</section>

</template>


<script>

export default {
	name: 'allProjects',
	data() {
		return {
			projects: [
				{
					title: 'Saint Daniel',
					rel: './img/other_project_1.jpg',
					pageLink: '/project__inner'
				},
				{
					title: 'Mobile',
					rel: './img/other_project_2.jpg',
					pageLink: '/project__inner'
				},
				{
					title: 'Loreal',
					rel: './img/other_project_3.jpg',
					pageLink: '/project__inner'
				},
				{
					title: 'Oggy',
					rel: './img/other_project_4.jpg',
					pageLink: '/project__inner'
				},
				{
					title: 'Gsk',
					rel: './img/other_project_5.jpg',
					pageLink: '/project__inner'
				}
			]
		}
	}
}
</script>

<style lang="scss">
</style>