<template>
	<!-- <transition name="slide"> -->
		<main class="wrapper">
			<projects></projects>
		</main>
	<!-- </transition> -->
</template>


<script>
import projects from '@/components/projects.vue';
import {EventBus} from '../EventBus.js';

export default {
  name: 'allProjects',
  components: {
		projects
	},
	mounted() {
		// on init page, event no index page 
		EventBus.$emit('on-main-page', {status: false});
	}
}
</script>

<style lang="scss">
</style>